import {AbstractControl, ValidationErrors} from '@angular/forms';
import {DATE_FORMAT_MONTH_YEAR, isPastMonth} from '@matchsource/date';
import {isEmpty} from '@matchsource/utils';
import {DateTime} from 'luxon';

export const PAST_MONTH_DATE_VALIDATOR_KEY = 'past_month_date';
export const PAST_MONTH_DATE_VALIDATOR_KEY_NEW = 'past_month_date_new';

export const pastMonthDateValidator =
  (validatorKey = PAST_MONTH_DATE_VALIDATOR_KEY) =>
  (control: AbstractControl): ValidationErrors | null => {
    const isValid =
      isEmpty(control.value) || isPastMonth(DateTime.fromFormat(control.value, DATE_FORMAT_MONTH_YEAR).toISO());

    return !isValid ? {[validatorKey]: {value: control.value}} : null;
  };
